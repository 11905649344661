import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/layout";
import {
  MapIcon,
  PhoneOutgoingIcon,
  ExternalLinkIcon,
  SortDescendingIcon,
} from "@heroicons/react/solid";
import { graphql, Link, useStaticQuery } from "gatsby";

function Stores(props) {
  const [data, setData] = useState([
    "Goa",
    "Karnataka",
    "Karnataka",
    "Karnataka",
  ]);
  const query = useStaticQuery(graphql`
  query MyQueryStores {
    allPrismicDealers {
      nodes {
        data  {
          state_name{
            text
          }
          address
          dealer_location {
            latitude
            longitude
          }
          dealer_name {
            text
          }
          dealership_image {
            gatsbyImageData
          }
          e_mail_address
          mobile_number
          shorthand_address
          slug
        }
      }
    }
  }
`);
console.log("qery",query)
const [stateData, setStateData] = useState([])
const {nodes} = query.allPrismicDealers
useEffect(() => {
  if(query){
    let statesArr = nodes.map(v=>{
      return v.data.state_name.text
    })
    let sData = statesArr.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    console.log("aqwerty",sData,statesArr)
    setStateData(sData)
  }
}, [query])
  return (
    <Layout>
      <div className="px-6 md:px-16 md:pt-10 lg:px-7 lg:w-[1024px] mx-auto">
        <h2 className="text-4xl md:text-5xl text-gray-700 leading-[72px] font-bold md:text-center mb-4 md:mb-10">
          List of Stores
        </h2>
        {stateData.map((val) => {
          return (
            <div className="mb-8">
              <h2 className="text-3xl md:text-4xl text-gray-700 leading-[54px] font-semibold">
                {val}
              </h2>
              <div className="h-[1px] w-full bg-[#D1D5DB] my-4"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-6">
                {nodes.filter(v=> v.data.state_name.text === val).map((store) => {
                  return (
                    <div className="w-full md:w-[312px] h-full mb-10">
                      <div className="w-full flex flex-col h-full">
                        <div className="img_container w-full h-[320px] bg-gray-400 relative">
                          <GatsbyImage
                            image={getImage(store.data.dealership_image)}
                            className="w-full h-full absolute"
                          />
                        </div>
                        <div className="py-4">
                          <h4 className="text-2xl text-gray-700 font-semibold leading-9">
                            {store.data.dealer_name.text}
                          </h4>
                          <h4 className="text-sm text-gray-500 font-normal leading-5 mt-2 mb-4">
                            {store.data.address}
                          </h4>
                        </div>
                          <div className="flex items-center mt-auto">
                            <MapIcon className="w-8 h-8 text-blue-500 mr-4 cursor-pointer" />
                            <PhoneOutgoingIcon className="w-8 h-8 text-blue-500 cursor-pointer" />
                            <Link to={"/dealers/" + store.data.slug} className="uppercase text-white bg-blue-500 rounded-[30px] text-sm font-bold text-center w-[150px] h-10 ml-auto flex justify-center items-center cursor-pointer">
                              Learn More{" "}
                              <ExternalLinkIcon className="text-white w-5 h-5" />
                            </Link>
                          </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

export default Stores;
